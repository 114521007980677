import React, { Component } from 'react';


class BasketItem extends Component {

    

    render() {
        
        const { item, index } = this.props;
        

        return (
            <tr>
                <th scope="row">
                    {item.book_name}
                </th>
                <td>
                    {item.author}
                </td>
                <td>
                    <button className='btn btn--sml btn--danger' onClick={ () => this.props.onItemRemoveClick(item) } >
                        Remove
                    </button>
                </td>
            </tr>
        );
    }
}

export default BasketItem;

import React, { Component } from 'react';

import TemplateItem from '../../components/TemplateItem';
import pattern from '../../assets/img/backgrounds/th-pattern.svg'
import SearchBar from '../../components/search-bar/SearchBar';
import { ROLE_TYPE_CHILD, ROLE_TYPE_TEACHER, ROLE_TYPE_USER } from "../../constants/role_constants";
import { checkAquiredRole } from "../../utils/Utils";

class TopicCatalouge extends Component {
  constructor(props) {
    super(props);
    //this.onFavouriteClick = this.onFavouriteClick.bind(this);
    this.props.onShowHeader();
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onSubmitSearchFormSubmit = this.onSubmitSearchFormSubmit.bind(this);
    this.props.onHideFavorite(true);
  }

  componentDidMount() {
    if (this.props.templateCatalouge === null) {
      this.props.requestTemplateCatalouge();
    }
  }

  onFavouriteClick(_id, _e) {
    this.props.requestChangeFavouriteTemplate(_id);
  }

  onSearchTermChange(_e) {
    //console.log("on search change: ", _e);
    this.props.onUpdateSearchTerm(_e.target.value);
  }

  onSubmitSearchFormSubmit() {
    this.props.onSubmitSearch();
  }

  render() {
    const {
      templateCatalouge,
      isLoggedIn,
      requestChangeFavouriteTemplate,
      requestTemplateDetails,
      onNavigateToClientPage,
      searchTerm,
      searchFilterCatalouge,
      onUpdateSearchFilterCatalouge,
      requestSearchFilterCatalouge,
      auth,
    } = this.props;

    const isChild = checkAquiredRole([ROLE_TYPE_CHILD], auth);
    const isParentOrTeacher = checkAquiredRole([ROLE_TYPE_USER, ROLE_TYPE_TEACHER], auth);

    return (
      // <div className='topic-block bgc-pattern' style={{backgroundImage: `url(${pattern})`}}>

      <div className="topic-block topic-block--grey-xl">
        {/* <SearchBar 
                    onSearchTermChange = { this.onSearchTermChange }
                    searchVisibility = { true } 
                    searchTerm={ searchTerm } 
                    searchFilterCatalouge = { searchFilterCatalouge } 
                    onSubmitSearch = { this.onSubmitSearchFormSubmit }
                    onUpdateSearchFilterCatalouge = { onUpdateSearchFilterCatalouge }
                    requestSearchFilterCatalouge = { requestSearchFilterCatalouge } /> */}

        <div className="topic-block-content">
          <h1 className="topic-block-content__title">Topics at your fingertips!</h1>
          <p className="topic-block-content__text">
            <p className="topic-block-content__text">
              {isParentOrTeacher
                ? "You're at the heart of Topic Heroes® - This is where we keep all our amazing topics. If you can't find what you're looking for always remember you can use the search icon in the top bar!  "
                : isChild
                ? "You're at the heart of Topic Heroes® - This is where we keep all our amazing topics, so go ahead and find the topic your parent/teacher has selected for you or see if something tickles your fancy. If you can't find what you're looking for always remember you can use the search icon in the top bar!"
                : " You're at the heart of Topic Heroes® - This is where we keep all our amazing topics, so go ahead and see if something tickles your fancy.If you can't find what you're looking for always remember you can use the search icon in the top bar!"}
            </p>
          </p>

          <div className="topic-block-content__grid">
            {templateCatalouge.map((e, i) => {
              return (
                <TemplateItem
                  key={i}
                  template={e}
                  isLoggedIn={isLoggedIn}
                  onNavigateToClientPage={onNavigateToClientPage}
                  requestTemplateDetails={requestTemplateDetails}
                  requestChangeFavouriteTemplate={requestChangeFavouriteTemplate}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TopicCatalouge;

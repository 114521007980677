import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectSponsor, fetchSponsors } from '../../store/actions';
import {
    Table,
    Button
  } from 'reactstrap';
//import { Link } from 'react-router-dom';

class SponsorList extends Component {
    
    componentDidMount() {
        
    }

    onClickItemEdit(_sponsor){
        const editLink = `/sponsors/edit/${_sponsor.id}`;
        this.props.onUpdateSponsor(_sponsor);
        this.props.history.push(editLink);
    }

    render() {

        if(this.props.sponsors === undefined || this.props.sponsors === null) {
            return null;
        }

        const renderedItems = this.props.sponsors.map((sponsor, i) => {
            const controlName = "valid-" + sponsor.id.toString();
            
            return (
                <tr key={i} >
                    <th scope="row">
                        {sponsor.id}
                    </th>
                    <td>
                        {sponsor.title}
                    </td>
                    <td>
                        {sponsor.created}
                    </td>
                    <td>
                        <button
                            className='th-admin-btn'
                            onClick={ this.onClickItemEdit.bind(this, sponsor) }
                        >
                            Edit
                        </button>
                    </td>
                </tr>
            );
        });

        
        return (
            <Table className="table table-striped" hover>
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Title
                        </th>
                        <th>
                            Created
                        </th>
                        <th >
                            <span className="css-gg4vpm"></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                   { renderedItems }
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        
    };
};

export default connect(
    mapStateToProps, 
    { selectSponsor }
)(SponsorList);
import React, { Component } from "react";

class Register extends Component {
  constructor(props) {
    super(props);

    this.onHandleTeacherClick = this.onHandleTeacherClick.bind(this);
    this.onHandleParentClick = this.onHandleParentClick.bind(this);
    this.props.onHideHeader();
  }

  state = { isBtnHover: false, isHelpShown: false };

  onHandleTeacherClick() {
    this.props.history.push("/register/teacher");
  }

  onHandleParentClick() {
    this.props.history.push("/register/parent");
  }

  onHoverEffect = () => {
    this.setState((prevState) => ({ isBtnHover: !prevState.isBtnHover }));
  };

  render() {
    const hello = "hello";
    const { isBtnHover } = this.state;

    return (
      <div className="register-block">

        <div className="register-block-content register-block-content--home">
          <div className="register-block-content__card">
            <h3 className="register-block-content__title">Topic Heroes<sup>®</sup> at home</h3>
            <p className="register-block-content__text">Why let the schools have all the fun! You can use Topic Heroes<sup>®</sup> at home too!</p>
            <button onMouseEnter={this.onHoverEffect} onMouseLeave={this.onHoverEffect} className="th-btn yellow-btn" onClick={this.onHandleParentClick}>
              Register today
            </button>
          </div>
          
        </div>

        <div className="register-block-content register-block-content--school">
            <div className="register-block-content__card">
              <h3 className="register-block-content__title">In the classroom</h3>
              <p className="register-block-content__text">Use Topic Heroes<sup>®</sup> to inspire your pupils to write creatively at school!</p>
                <button className="th-btn yellow-btn" onClick={this.onHandleTeacherClick}>
                  Register today
                </button>
            </div>
        </div>
        
      </div>
    );
  }
}

export default Register;

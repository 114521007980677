import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';

class SearchFilterOptionList extends Component {


    // constructor(props) {
    //   super(props);
    // }

    onSelectedOptionDropdownChange(_sfid, _e) {
        this.props.onSelectedOptionDropdownChange(_sfid, _e);
    }

    onSelectedOptionChange(_optionId) {
        this.props.onSelectedOptionChange(_optionId);
    }

    render() {
        var visibility = "hide";
     
        if (this.props.searchVisibility) {
          visibility = "show";
        }

        const { searchFilterCatalouge, assignMode } = this.props;

        const formGroups = searchFilterCatalouge.map((e, i) => {

            const isMultiSelect = e.multiselect && e.multiselect === true;
    
            var selectControl = null;
    
            
            var selectOption = "";
    
            if(isMultiSelect === false) {
              for(var oj = 0; oj < e.options.length; oj++) {
                if(e.options[oj].selected === true) {
                  selectOption = e.options[oj].title;
                }
              }
            }
    
            selectControl = (
              <select className="control" placeholder={e.title} onChange = { this.onSelectedOptionDropdownChange.bind(this, e.id) } value={ selectOption } >
                <option value="" >Please Select</option>
                {
                  e.options.map((opt, j) => {
                    return (
                      <option key={j} value={opt.title} >{ opt.title }</option>
                    )
                  })
                }
              </select>
            );
    
            return (
        
                (isMultiSelect === true || assignMode === true) ? (
                    <FormGroup className=""  key={e.id}>
                        <label
                            htmlFor={e.title}
                            >
                            {e.title}
                        </label>
            
                        {
                            e.options.map((opt, j) => {
                                return (
                                    <div className="th-check" key={opt.id} >
                                        <Input
                                        id={"option_" + opt.id}
                                        type= { "checkbox" }
                                        //type= { assignMode === true && isMultiSelect === false ? "radio" : "checkbox" }
                                        value = { opt.selected }
                                        checked = { opt.selected }
                                        onChange = { this.onSelectedOptionChange.bind(this, opt.id) }
                                        
                                        />
                                        {' '}
                                        <label >
                                        { opt.title }
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </FormGroup>
                    
                ) : (  

                    <FormGroup className="app-field-wrapper"  key={e.id}>
                        <label
                            htmlFor={e.title}
                            >
                            {e.title}
                        </label>
            
                        {selectControl}
                    </FormGroup>
                )
    
              
    
            );
        });
    
        return (
            [formGroups]
        );
      }
}
 
export default SearchFilterOptionList;

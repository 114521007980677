
import React, { Component } from 'react';
import Loader from '../../components/Loader';
import { VALID_EMAIL_REGEX , VALID_MOBILE_NUMBER } from "../../constants/regex";


import { defaultAddress } from '../../constants/data';

class AccountDetails extends Component {

    constructor(props) {
        super(props);

         this.props.onShowHeader();
         
        this.state = {
            changes: null,
            invalidEmail :false,
            invalidMobile : false
        };

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onChangeVisible = this.onChangeVisible.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onClickNewAddress = this.onClickNewAddress.bind(this);
        this.onClickEditAddress = this.onClickEditAddress.bind(this);
        this.props.onHideFavorite(true);
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.changes !== props.user) {
          return {
            changes: props.user,
          }
        }
        return null
    }

    onChangeFirstName(_e) {
       

        var user = this.state.changes;
        user.first_name =  _e.target.value;
        this.saveChanges(user);
    }

    onChangeLastName(_e) {
        var user = this.state.changes;
        user.last_name =  _e.target.value;
        this.saveChanges(user);
    }

    onChangeEmail(_e) {
        var user = this.state.changes;
        user.email =  _e.target.value;
        this.saveChanges(user);

         var regex = new RegExp(VALID_EMAIL_REGEX);
         this.setState({
            invalidEmail: !regex.test(user.email)
         })
    }

    onChangeMobile(_e) {
        var user = this.state.changes;
        user.mobile =  _e.target.value;
        this.saveChanges(user);

        var regex = new RegExp(VALID_MOBILE_NUMBER);
         this.setState({
            invalidMobile: !regex.test(user.mobile)
         })
    }

    onChangeVisible() {
        var user = this.state.changes;
        user.display_welcome =  !user.display_welcome;
        this.saveChanges(user);
    }

    saveChanges(_user) {
        this.setState({
           changes: _user 
        });
    }

    onSubmitForm(_e){
        _e.preventDefault();

         var regex = new RegExp(VALID_EMAIL_REGEX);
         if(!regex.test(this.state.changes.email)){
             return;
         }else{
             const user = this.state.changes;
            this.props.requestUpdateUserDetails(user);
         }
    }

    onClickNewAddress(){
        const nwAddLink  = "/user/addressbook/add";
        this.props.onUpdateAddress(defaultAddress);
        this.props.history.push(nwAddLink);
    }

    onClickEditAddress(_address) {
        const editLink = `/user/addressbook/edit/${_address.id}`;
        this.props.onUpdateAddress(_address);
        this.props.history.push(editLink);
    }

    onClickRemoveAddress(_address) {
        var z = confirm("Confirm you want to delete this address? Warning this can not be undone!");
        if (z !== true) {
            return;
        }
        this.props.requestDeleteAddress(_address);
    }

    validateForm(){
        var emailRegex = new RegExp(VALID_EMAIL_REGEX);
        return emailRegex.test(this.state.changes.email)
        // if(this.state.changes.mobile != ""){
        //     var mobileRegex = new RegExp(VALID_MOBILE_NUMBER);
        //     return emailRegex.test(this.state.changes.email) && mobileRegex.test(this.state.changes.mobile)
        // }else{
        //       return emailRegex.test(this.state.changes.email)
        // }
    }   

    render() {

        const user = this.state.changes;

        const invalidEmail = this.state.invalidEmail;

        if (user === null) {
          return <Loader />;
        }

        var addressCount = user.address_book.length;
        const isVisible = user.display_welcome;

        return (
          <div>
            <div className="section-block">
              <div className="section-block-content">
                <h2 className="section-block-content__title">Account details</h2>
                <p className="section-block-content__text">Use the from below to update your account details.</p>

                <form className="app-form" onSubmit={this.onSubmitForm}>
                  <div className="app-form__content">
                    <div className="app-form__row">
                      <div className="app-field-wrapper">
                        <label htmlFor="first_name">First name</label>

                        <input
                          name="first_name"
                          placeholder="First Name"
                          type="text"
                          onChange={this.onChangeFirstName}
                          value={user.first_name}
                          maxLength="40"
                        />
                      </div>

                      <div className="app-field-wrapper">
                        <label htmlFor="last_name">Last name</label>

                        <input name="last_name" placeholder="Last Name" type="text" onChange={this.onChangeLastName} value={user.last_name} maxLength="40" />
                      </div>
                    </div>

                    <div className="app-field-wrapper">
                      <label htmlFor="email">Email address {user.email_confirmed === true ? "(verified)" : "(unverified)"}</label>

                      <input autoComplete="on" name="email" placeholder="Email" type="email" onChange={this.onChangeEmail} value={user.email} maxLength="80" />
                      {invalidEmail && <p className="t-sml t-error font-weight-bold m-2">Invalid Email</p>}
                      {user.new_email && user.email != user.new_email && (
                        <div className="notify-bar t-sml mt-1">
                          You have requested to update your email, Please confirm the change by verifying the email sent to you.
                        </div>
                      )}
                    </div>

                    <div className="app-field-wrapper">
                      <label htmlFor="mobile">Mobile Number {user.mobile_confirmed === true ? "(verified)" : "(unverified)"} </label>
                      <input
                        autoComplete="on"
                        name="mobile"
                        placeholder="Mobile Number"
                        type="text"
                        onChange={this.onChangeMobile}
                        value={user.mobile}
                        maxLength="80"
                      />
                      {user.new_mobile && user.mobile != user.new_mobile && (
                        <div className="notify-bar t-sml mt-1">
                          You have requested to update your mobile, Please confirm the change by verifying the email sent to you.
                        </div>
                      )}
                    </div>

                    <div className="app-field-wrapper--inline">
                      <input onChange={this.onChangeVisible} type="checkbox" checked={isVisible} />

                      <label check={"true"}>Display welcome screen</label>
                    </div>

                    <div className="app-form__buttons">
                      <button className={`btn ${this.validateForm() ? "btn btn--success" : "btn btn--secondary"}`} disabled={!this.validateForm()}>
                        Save changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="section-block section-block--grey-xl">
              <div className="section-block-content">
                <h2 className="section-block-content__title">Address book</h2>
                {addressCount < 1 ? (
                  <p className="section-block-content__text">You've not added any addresses yet! Go ahead and hit 'Add address' to create one.</p>
                ) : (
                  <p className="section-block-content__text">This is where your saved addresses are stored. You can edit, delete and add new addresses here.</p>
                )}

                {user.address_book.map((e, i) => {
                  const addressCombined = ` ${e.addline_1}, ${e.addline_2}, ${e.city}, ${e.postcode}`;

                  return (
                    <div className="address-block__grid" key={i}>
                      <div className="address-row">
                        <div className="address-content">
                          <div className="address-content__icon">
                            <i class="fa-light fa-location-dot"></i>
                          </div>

                          <div className="address-detail">
                            <h3 className="address-detail__title">{e.addline_1}</h3>
                            <p className="address-detail__text">{addressCombined}</p>
                          </div>
                        </div>

                        <div className="address-actions">
                          <button className="btn btn--sml btn--secondary" onClick={() => this.onClickEditAddress(e)}>
                            Edit address
                          </button>
                          <button className="btn btn--sml btn--danger" onClick={() => this.onClickRemoveAddress(e)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                <button className="btn btn--success" onClick={this.onClickNewAddress}>
                  Add address
                </button>
              </div>
            </div>
          </div>
        );
    }
}

export default AccountDetails;
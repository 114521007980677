import React, { Component } from "react";
import axios from "../../apis/thresource";
import queryString from "query-string";
import { VALID_PASSWORD_REGEX } from "../../constants/regex";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.props.onHideHeader();

    var token = queryString.parse(this.props.location.search).token;
    this.state = {
      newPassword: "",
      confirmPassword: "",
      resetToken: token?.replace(/ /g, "+"),
      email: queryString.parse(this.props.location.search).email,
      passwordMismatched: false,
    };

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangePassword(_e) {
    this.setState({
      newPassword: _e.target.value,
    });
  }

  onChangeConfirmPassword(_e) {
    this.setState({
      confirmPassword: _e.target.value,
    });
  }

  onSubmitForm(_e) {
    _e.preventDefault();

    var regex = new RegExp(VALID_PASSWORD_REGEX);
    if (!regex.test(this.state.newPassword)) {
      this.props.onSetAlert([{ message: "password does not satisfy the given combination" }]);
      return;
    }

    if (this.state.newPassword != this.state.confirmPassword) {
      this.props.onSetAlert([{ message: "The password and confirm password does not match." }]);
      return;
    }

    const postData = {
      email: this.state.email,
      token: this.state.resetToken?.trim(),
      password: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };

    axios
      .post(`/api/auth/reset-password`, JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.props.onSetAlert(response.data.errors);
        }
        if (response.data.success) {
          this.props.onSetAlert([{ message: "password changed successfully!" }]);
          this.props.history.push("/login");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          //console.log("server data:", error.response.data);
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  render() {
    const { newPassword, confirmPassword } = this.state;

    return (
      <div className="reset-block">
        <form className="reset-block-card" onSubmit={this.onSubmitForm}>
          <div>
            <div className="reset-card-content__icon">
              <i className="fa-light fa-paper-plane"></i>
            </div>
            <h2 className="reset-card-content__title">Change password</h2>
            <p className="reset-card-content__text">Create a new password by entering it below.</p>
          </div>

          <div className="reset-card-content__form">
            <div className="app-field-wrapper">
              <label>New Password</label>
              <input autoComplete="on" name="password" type="password" placeholder="Password" onChange={this.onChangePassword} value={newPassword} />
            </div>
            <div className="desc-field-wrapper">
              <label>Password must be at least 8 characters long, contain a number and an uppercase letter and special character</label>
            </div>
          </div>

          <div className="app-field-wrapper">
            <label>Confirm Password</label>
            <input autoComplete="on" name="password" type="password" placeholder="Password" onChange={this.onChangeConfirmPassword} value={confirmPassword} />
          </div>

          <button className="col-12 btn btn--primary-fw mt-5" type="submit">
            {" "}
            Finish{" "}
          </button>
        </form>
      </div>
    );

  }

}

export default ResetPassword;


import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '../constants/string_constants';
import { NavLink as RRNavLink, Link } from 'react-router-dom';
import axios from '../apis/thresource';

var pollCancelToken = -1;

class Login extends Component {

    constructor(props){
        super(props);

         this.props.onHideHeader();

        this.state = {
            email: "",
            emailCodeSent: false,
            checkingToken: false,
            invalidPassword:false,
        };

        this.onChangeEmail  = this.onChangeEmail.bind(this);
        //this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
        this.onChangePassword  = this.onChangePassword.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onToggleCheckToken = this.onToggleCheckToken.bind(this);
        this.onGoToChildLogin = this.onGoToChildLogin.bind(this);

        this.onRequestReset = this.onRequestReset.bind(this);
        this.navigateToRegister = this.navigateToRegister.bind(this);
        
    }

    componentDidMount(){
        this.setState({
            checkingToken: true
        }, () => {
            this.onToggleCheckToken();
            pollCancelToken = setInterval(this.onToggleCheckToken, 25500);
        })
        
    }

    componentWillUnmount() {
        if(pollCancelToken > -1) {
            clearInterval(pollCancelToken); 
            pollCancelToken = -1;
        }
    }

    onToggleCheckToken(){
        
        this.props.requestCheckToken();


        this.setState({
            checkingToken: false
        });
    }

    onChangeEmail(_e){
        this.setState({
            email: _e.target.value
        });
    }

     onChangePassword(_e){
        this.setState({
            password: _e.target.value
        });
    }

    // onChangeVerificationCode(_e){
    //     this.setState({
    //         verificationCode: _e.target.value
    //     });
    // }

    onSubmitForm(_e) {
        _e.preventDefault();

        this.props.onClearAlert();
        
        const postData = { 
            email: this.state.email, 
            password: this.state.password
            //token: this.state.verificationCode
        };

        axios.post('/api/auth/login', JSON.stringify(postData))
        .then(response => {
            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }

            if(response.data.success){
                const user = {... response.data.data }
                this.props.onUpdateUser(user);
                if(response.data.data.is2FAEnabled){
                    this.props.onUpdateAuthToken(response.data.data.token);
                    this.props.history.push('/login/verify');
                }else{
                    this.props.onUpdateAuth(response.data.data.token, true);
                }
            }
        })
        .catch(error => {
            console.log("server error response: ", error);
            if (error.response && error.response.data) {
                //console.log("server data:", error.response.data);
                this.props.onSetAlert(error.response.data.errors);
            }
        });
    }

    onRequestReset(){
         this.props.history.push("/forgot-password");
    }

    navigateToRegister(){
        this.props.history.push("/register");
    }

    onGoToChildLogin() {
        this.props.onNavigateToClientPage(`/login-child`);
    }

    render() {

        const { email, password, emailCodeSent, checkingToken } = this.state;

        return (
            <div className='login-block'>

                { (checkingToken === true) ? <div className='token-check'>CHECKING TOKEN</div> : "" }

                {

                    (emailCodeSent === false) ? (

                        <form className="login-block-card" onSubmit={ this.onSubmitForm }>

                            <div className='login-card-content'>

                                <div>
                                    <div className='login-card-content__icon'><i className="fa-light fa-lock"></i></div>
                                    <h2 className='login-card-content__title'>Account login</h2>
                                    <p className="login-card-content__text">Enter your email address and password to login. If you have two factor authentication enabled then we will send you a code to login.</p>
                                </div>

                                <div className='app-field-wrapper'>
                                    <label>Email Address</label>
                                    <input autoComplete="on" name="email" type="text" maxLength="128" placeholder="name@domain.co.uk" onChange={ this.onChangeEmail } value={ email } />
                                </div>

                                <div className='app-field-wrapper'>
                                    <label>Enter Password</label>
                                    <input autoComplete="on" name="password" type="password"  placeholder="Password" onChange={ this.onChangePassword } value={ password } />
                                </div>
    
                                    <button className="btn btn--primary-fw" type="submit"> Sign in </button>

                                <div className='login-card-footer'>
                                    <p className='login-card-footer__link'>Forgotten password? <span onClick={this.onRequestReset}>Get a reset link</span></p>
                                    <p className='login-card-footer__link'>New to Topic Heroes? <span onClick={this.navigateToRegister}>Register here</span></p>
                                </div>

                            </div>

                        </form>

                    ) : (

                        <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">

                            <div className='th-form-inner'>
                                
                                <h2 className="hw-font t-purple t-ctr">Link sent</h2>

                                <p className="t-grey t-ctr"> An email has been sent to { email }. Follow the link in the email to complete the login.</p>
                            
                            </div>

                        </div>
                    )
                }
            
                <div className='switch-btn'>
                    <button onClick={ this.onGoToChildLogin } >Go to child login <i className="fa-light fa-circle-chevron-right fa-xl"></i></button>
                </div>

            </div>
            

            
        );
    }
}

export default Login;










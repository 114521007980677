import React, { Component } from "react";
import { NavLink as RRNavLink, Link } from "react-router-dom";
//import { PRODUCT_NAME, COMPANY_NAME } from '../constants/string_constants';
import MenuButton from "../components/MenuButton";
import FlyMenu from "../components/FlyMenu";
import SearchBar from "../components/search-bar/SearchBar";
import logo from "../assets/img/topic-heroes-logo.svg"; // with import
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // NavbarText,
} from "reactstrap";

class NavMenu extends Component {
  constructor(props) {
    super(props);

    this.onHandleMouseDown = this.onHandleMouseDown.bind(this);
    this.onHandleMouseDownNavigation = this.onHandleMouseDownNavigation.bind(this);
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onSubmitSearchFormSubmit = this.onSubmitSearchFormSubmit.bind(this);
  }

  onHandleMouseDown(e) {
    this.props.onToggleMenu();
    //this.props.

    //console.log("clicked", this.props.isMenuVisible.toString());
    e.stopPropagation();
  }

  onHandleMouseDownNavigation(_e) {
    if (this.props.isSearchVisible === true) {
      this.props.onToggleSearch();
    }
    if (this.props.isMenuVisible === true) {
      this.props.onToggleMenu();
    }

    this.props.onNavigateToClientPage(_e);
    //_e.stopPropagation();
  }

  // toggle = () =>  {
  //     this.setState({
  //         isOpen: !this.state.isOpen
  //     })
  // };

  onSearchTermChange(_e) {
    //console.log("on search change: ", _e);
    this.props.onUpdateSearchTerm(_e.target.value);
  }

  onSubmitSearchFormSubmit() {
    this.setState({
      isSearchVisible: false,
    });

    this.props.onSubmitSearch();
    this.props.onToggleSearch();
  }

  render() {
    //const { isOpen } = this.state;

    const { searchTerm, searchFilterCatalouge, isLoggedIn, auth, onLogout, onToggleEditorMode, onUpdateSearchFilterCatalouge, isMenuVisible, isSearchVisible } =
      this.props;
    //const logo = require('./logo.jpeg'); // with require
    const menuOpenClass = isMenuVisible === true ? "fa-xmark-large " : "fa-bars";
    return (
      <header>
        <FlyMenu
          onToggleMenu={this.props.onToggleMenu}
          onToggleEditorMode={onToggleEditorMode}
          onLogout={onLogout}
          menuVisibility={isMenuVisible}
          isLoggedIn={isLoggedIn}
          auth={auth}
          requestLoginBypass={this.props.requestLoginBypass}
        />

        <div className={"app-header"}>
          <div className={"app-header-menu"}>
            <MenuButton handleMouseDown={this.onHandleMouseDown}>
              <i className={`fa-light ${menuOpenClass} fa-fw`}></i>
            </MenuButton>
            <MenuButton handleMouseDown={() => this.onHandleMouseDownNavigation("/")}>
              <i className={"fa-light fa-house-blank fa-fw"}></i>
            </MenuButton>
            <MenuButton handleMouseDown={() => this.onHandleMouseDownNavigation("/topics")}>
              <i className={"fa-light fa-book-open-cover fa-fw"}></i>
            </MenuButton>
            <MenuButton handleMouseDown={this.props.onToggleSearch}>
              <i className={"fa-light fa-search fa-fw"}></i>
            </MenuButton>
            {this.props.isLoggedIn && this.props.showFavoriteIcon && (
              <MenuButton handleMouseDown={this.props.onNavigateToFavorite}>
                <i className={"fa-light fa-heart"}></i>
              </MenuButton>
            )}
          </div>

          <Link to="/">
            <div className={"app-header__logo"} tag={RRNavLink}>
              <img src={logo} alt="Topic Heroes" />
            </div>
          </Link>
        </div>

        <SearchBar
          onSearchTermChange={this.onSearchTermChange}
          searchVisibility={isSearchVisible}
          searchTerm={searchTerm}
          searchFilterCatalouge={searchFilterCatalouge}
          onSubmitSearch={this.onSubmitSearchFormSubmit}
          onUpdateSearchFilterCatalouge={onUpdateSearchFilterCatalouge}
          requestSearchFilterCatalouge={this.props.requestSearchFilterCatalouge}
        />
      </header>
    );
  }
}

export default NavMenu;

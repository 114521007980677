
import React, { Component } from 'react';
import SchoolList from '../../../components/schools/SchoolList';
import { defaultSchool } from '../../../constants/data';

class Schools extends Component {

    constructor(props) {
        super(props);

        this.onClickAddSchool = this.onClickAddSchool.bind(this);
         this.props.onHideFavorite(true);
    }

    onClickAddSchool() {

        this.props.onUpdateSchool(defaultSchool);
        this.props.history.push("/schools/add");
    }
    

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>
                    <div className='th-panel bgc-white pall-l th-br'>

                        <div className="spc-b-l">
                            <h2 className="hw-font spc-b-s t-purple">Manage Schools</h2>
                            <p></p>
                        </div>

                        <SchoolList 
                            auth = { this.props.auth }
                            school = { this.props.school }
                            onUpdateSchool = { this.props.onUpdateSchool }
                            history= { this.props.history } 
                        />

                        <div>
                            <button className='th-admin-btn' onClick={ this.onClickAddSchool }>Add School</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Schools;
import React, { Component } from 'react';
import axios from '../apis/thresource';
import Loader from '../components/Loader';


class CompleteLogin extends Component {

    constructor(props){
        super(props);

        this.state = {
            verificationCode: "",
        };

        this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
        this.onClickSendAnotherCode = this.onClickSendAnotherCode.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onChangeVerificationCode(_e){
        this.setState({
            verificationCode: _e.target.value
        });
    }


    onClickSendAnotherCode(_e) {
        
        this.setState({
            verificationCode: ''
        });

        const postData = {
            email: this.props.user.email
        };

        axios.post('/api/account/resendvirifynumber', JSON.stringify(postData))
        .then(response => {

            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }

            if(response.data.success) {
                const alert = { field_name: "", message: "Code resent" };
                this.props.onSetAlert([alert]);
            }
            
        })
        .catch(error => {
            if (error.response && error.response.data) {
                this.props.onSetAlert(error.response.data.errors);
            }
        });


    }
    
    onSubmitForm(_e){
        _e.preventDefault();


        const postData = {
            email: this.props.user.email,
            token: this.state.verificationCode,
            update_2fa:false
        };

        axios.post('/api/account/verifynumber', JSON.stringify(postData))
        .then(response => {
            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }
            if(response.data.success) {
                this.props.onUpdateAuth(this.props.authToken, true);
            }
            
        })
        .catch(error => {
            if (error.response && error.response.data) {
                //console.log("server data:", error.response.data);
                this.props.onSetAlert(error.response.data.errors);
            }
        });
    }

    render() {

        const { user } = this.props;
        const { verificationCode } = this.state;

        if(!user) {
            return (<Loader />);
        }

        return (
            <div className='login-block'>

                <form className="login-block-card" onSubmit={ this.onSubmitForm } >
                    
                    <div className="login-card-content">
                        
                        <div>
                            <div className="login-card-content__icon"><i className="fa-light fa-shield-check"></i></div>
                            <h2 className="login-card-content__title">Authentication</h2>
                            <p className="login-card-content__text">We've sent you a code to {user?.mobile} Please enter the code to login.</p>
                        </div>
                        
                        <div className="app-field-wrapper">
                            <label>Enter Code</label>
                            <input type="text" placeholder='&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;' onChange={ this.onChangeVerificationCode } value={ verificationCode } />
                        </div>
                       
                        <button className="btn btn--primary" type="submit"> Sign in </button>

                        <div className='login-card-footer'>
                            <p className='login-card-footer__link'>Not received a code? <span onClick={this.onClickSendAnotherCode}> Send another</span></p>
                        </div>

                    </div>

                </form>

            </div>
        );
    }
}

export default CompleteLogin;



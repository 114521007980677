

import React, { Component } from 'react';

class ChildAccountItem extends Component {

    onClickSwitchChildAccount(_child) {
        const switchLink = `/login-child?u=${_child.username}`;
        this.props.onNavigateToClientPage(switchLink);
    }

    render() {

        const { child, allowEdit } = this.props;

        return (
            <div className='child-account-row'>
                <div className='child-account-content'>
                    <div className='child-account-content__icon'><i className="fa-light fa-user"></i></div>

                    <div className='child-account-detail'>
                        <h3 className='child-account-detail__title'>{`${child.first_name} ${child.last_name}`}</h3>
                        <p className='child-account-detail__text'>Username <span className='font-weight-bold'>{`${child.username}`}</span></p>
                    </div>
                </div>

                <div className='child-account-actions'>
                    {
                        (allowEdit) ? (
                            <button className='btn btn--sml btn--secondary' onClick={() => this.props.onClickEditChildAccount(child)} >Edit account</button>
                        ) : null
                    }
                    <button className='btn btn--sml btn--success' onClick={() => this.onClickSwitchChildAccount(child)} >Login as {`${child.first_name}`} </button>
                </div>
                
            </div>

        );
    }
}

export default ChildAccountItem;



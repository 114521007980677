import React, { Component } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

class EditFilter extends Component {

    constructor(props){
        super(props);

        this.state = {
            changes: null
        }

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeMultiselect = this.onChangeMultiselect.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSaveChanges = this.onSaveChanges.bind(this);
         this.props.onHideFavorite(true);

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.changes !== props.searchFilter) {
          return {
            changes: props.searchFilter,
          }
        }
        return null
    }

    onChangeTitle(_e){

        var { changes } = this.state;
        changes.title = _e.target.value;
        this.onSaveChanges(changes);
    }

    onChangeMultiselect() {
        var { changes } = this.state;
        changes.multiselect = !changes.multiselect;
        this.onSaveChanges(changes);
    }


    onSaveChanges(_searchFilter) {
        this.setState({
            changes: _searchFilter
        });
    }


    onSubmitForm(_e) {
        console.log("on edit form submitted", _e);
        _e.preventDefault();

        const { changes } = this.state;

        const searchFilter = {
            id: changes.id,
            title: changes.title, 
            multiselect: changes.multiselect
        };

        this.props.requestUpdateFilter(searchFilter);
    }

    render() {

        const { changes } = this.state;
        if(changes === null) {
            return <div>Loading...</div>
        }

        const titleText = changes.id < 1 ? "Add Filter" : "Edit Filter";

        return (

            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel th-br bgc-white pall-l'>

                        <h2 className="hw-font t-purple spc-b-s">{ titleText }</h2>

                        <p className="spc-b-l">Update the name of the filter, check if you want the filter to be displayed as multi-select options.</p>

                        <form className="th-form-container has-validation-callback" onSubmit={ this.onSubmitForm }>

                            <div className="th-form-inner">
                                
                                <div className="th-field-wrapper">

                                    <label>Filter Title</label>

                                    <input type="text" placeholder="Title" onChange={ this.onChangeTitle } value={ changes.title } />

                                </div>

                                <div className="th-form-inline-label">

                                    <input onChange={ this.onChangeMultiselect } type="checkbox" checked={ changes.multiselect } />
                                    
                                    <label>Multiselect</label>

                                </div>

                                <div className="form-buttons">
                                    <button className="th-admin-btn" type="submit">Save Changes</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>

            </div>
        );
    }
}

export default EditFilter;
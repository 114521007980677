import { combineReducers } from "redux";
import { 
    TEMPLATES_SELECT_ACTION,
    TEMPLATES_FETCH_ACTION,
    SPONSOR_FETCH_ACTION,
    SPONSOR_SELECT_ACTION,
    SEARCH_FILTER_FETCH_ACTION,
    SEARCH_FILTER_SELECT_ACTION,
    SCHOOL_FETCH_ACTION,
    SCHOOL_SELECT_ACTION,
    BASKET_FETCH_ACTION,
    ORDER_HISTORY_FETCH_ACTION,
    SUBJECT_FETCH_ACTION,
    SUBJECT_SELECT_ACTION
} from "../../constants/action_types";


const templateReducer = (templates = [], action) => {

    if(templates === undefined) {
        templates = [];
    }

    if(action.type === TEMPLATES_FETCH_ACTION) {
        return action.payload;
    }

    return templates;
};

const selectedTemplateReducer = (selectedTemplate = null, action) => {
    if(action.type === TEMPLATES_SELECT_ACTION) {
        return action.payload;
    }

    return selectedTemplate;
};


const sponsorReducer = (sponsors = [], action) => {

    if(sponsors === undefined) {
        sponsors = [];
    }

    if(action.type === SPONSOR_FETCH_ACTION) {
        return action.payload;
    }

    return sponsors;
};



const selectedSponsorReducer = (selectedSponsor = null, action) => {
    if(action.type === SPONSOR_SELECT_ACTION) {
        return action.payload;
    }

    return selectedSponsor;
};


const schoolReducer = (schools = [], action) => {

    if(schools === undefined) {
        schools = [];
    }

    if(action.type === SCHOOL_FETCH_ACTION) {
        return action.payload;
    }

    return schools;
};



const selectedSchoolReducer = (selectedSchool = null, action) => {
    if(action.type === SCHOOL_SELECT_ACTION) {
        return action.payload;
    }

    return selectedSchool;
};




const subjectReducer = (subjects = [], action) => {

    if(subjects === undefined) {
        subjects = [];
    }

    if(action.type === SUBJECT_FETCH_ACTION) {
        return action.payload;
    }

    return subjects;
};



const selectedSubjectReducer = (selectedSubject = null, action) => {
    if(action.type === SUBJECT_SELECT_ACTION) {
        return action.payload;
    }

    return selectedSubject;
};


const searchFilterReducer = (filters = [], action) => {

    if(filters === undefined) {
        filters = [];
    }

    if(action.type === SEARCH_FILTER_FETCH_ACTION) {
        return action.payload;
    }

    return filters;
};

const selectedSearchFilterReducer = (selectedFilter = null, action) => {
    if(action.type === SEARCH_FILTER_SELECT_ACTION) {
        return action.payload;
    }

    return selectedFilter;
};

const basketReducer = (basket = null, action) => {


    if(action.type === BASKET_FETCH_ACTION) {
        return action.payload;
    }

    return null;
};

const orderHistoryReducer = (orders = [], action) => {

    if(orders === undefined) {
        orders = [];
    }

    if(action.type === ORDER_HISTORY_FETCH_ACTION) {
        return action.payload;
    }

    return orders;
};


export const reducers = {
    templates: templateReducer,
    selectedTemplate: selectedTemplateReducer,
    filters: searchFilterReducer,
    selectedFilter: selectedSearchFilterReducer,
    sponsors: sponsorReducer,
    selectedSponsor: selectedSponsorReducer,
    schools: schoolReducer,
    selectedSchool: selectedSchoolReducer,
    basket: basketReducer,
    orderHistory: orderHistoryReducer,
    subjects: subjectReducer,
    selectedSubject: selectedSubjectReducer
};

// export default combineReducers({
//     templates: templateReducer,
//     selectedTemplate: selectedTemplateReducer,
//  });
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { requestFetchBasket } from '../../store/actions';
import Loader from '../Loader';
import BasketItem from './BasketItem';

class BasketList extends Component {
    
    constructor(props) {
        super(props);

        
        this.onItemRemove = this.onItemRemove.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    componentDidMount() {
        this.props.requestFetchBasket(this.props.getRequestHeaders());
    }
    

    onItemRemove(_item){
        this.props.requestRemoveFromBasket(_item.id);
    }

    onFormSubmit(_e){
        _e.preventDefault();
        this.props.requestCheckout();
    }

    render() {

        if(this.props.basket === undefined || this.props.basket === null)  {
            return null;
        }
        if(this.props.basket.items === undefined || this.props.basket.items === null)  {
            return null;
        }

        const renderedItems = this.props.basket.items.map((bi, i) => {

            return (

                <BasketItem 
                    key={i} 
                    index= { i } 
                    item = { bi } 
                    onItemRemoveClick = { this.onItemRemove }  />

            );
        });

        if(this.props.basket.items.length < 1) {
            return (<p>Basket is empty</p>);
        }
        
        return (
            
                <div className='app-table-content'>

                    <table className="app-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Author</th>
                                <th className='app-table__button'></th>
                            </tr>

                        </thead>
                        <tbody>
                            { renderedItems }
                        </tbody>
                    </table>

                    <form onSubmit= { this.onFormSubmit } >
                        <button className="btn btn--success" type="submit"> Checkout </button>
                    </form>

                </div>            
        );
    }
}

const mapStateToProps = (state) => {
    
    return {

        basket: state.basket
        
    };
};

export default connect(
    mapStateToProps, 
    { requestFetchBasket }
)(BasketList);
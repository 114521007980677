import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectSchool, fetchSchools } from '../../store/actions';
import {
    Table
} from 'reactstrap';

class SchoolList extends Component {
    
    componentDidMount() {
        this.props.fetchSchools(this.props.auth);
    }

    onClickItemEdit(_school){
        const editLink = `/schools/edit/${_school.id}`;
        this.props.onUpdateSchool(_school);
        this.props.history.push(editLink);
    }

    render() {

        if(this.props.schools === undefined || this.props.schools === null) {
            return null;
        }

        const renderedItems = this.props.schools.map((school, i) => {
            
            return (
                <tr key={i} >
                    <th scope="row">
                        {school.id}
                    </th>
                    <td>
                        {school.title}
                    </td>
                    <td>
                        <button
                            className='th-admin-btn'
                            onClick={ this.onClickItemEdit.bind(this, school) }
                        >
                            Edit
                        </button>
                    </td>
                </tr>
            );
        });

        
        return (
            <Table className="table table-striped" hover>
                <thead>
                    <tr>
                        <th>
                            #
                        </th>
                        <th>
                            Title
                        </th>
                        <th >
                            <span className="css-gg4vpm"></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                   { renderedItems }
                </tbody>
            </Table>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        schools: state.schools
    };
};

export default connect(
    mapStateToProps, 
    { selectSchool, fetchSchools }
)(SchoolList);
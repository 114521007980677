import React, { Component, createRef } from "react";
import { NavLink as RRNavLink, Link } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
// import {
//     // Carousel,
//     // CarouselIndicators,
//     // CarouselControl,
//     // CarouselItem,
//     // CarouselCaption,
//     // UncontrolledCarousel,
//     Row,
//     Col
// } from 'reactstrap';

//import TemplateButton from '../../components/template-list/TemplateButton';
//import TopicItem from '../../components/TopicItem';

import astronaught from "../../assets/img/graphics/astronaught.svg";
import moon from "../../assets/img/graphics/moon.svg";
import space_bg from "../../assets/img/backgrounds/space-bg-1920x1080.jpg";
import deliveryIcon from "../../assets/img/icons/delivery-icon.svg";
import sponsorshipIcon from "../../assets/img/icons/sponsor-icon.svg";
import publicityIcon from "../../assets/img/icons/publicity-icon.svg";
import book from "../../assets/img/graphics/book.png";
import joy from "../../assets/img/graphics/joy.png";
import inspire from "../../assets/img/graphics/inspire.png";
import thGroupImage from "../../assets/img/graphics/topic-heroes-group.png";
import pattern from "../../assets/img/backgrounds/th-pattern.svg";
import TemplateItem from "../../components/TemplateItem";
import ProjectItem from "../../components/ProjectItem";
import ChildAccountItem from "../User/ChildAccountItem";
import { ROLE_TYPE_CHILD, ROLE_TYPE_TEACHER, ROLE_TYPE_USER } from "../../constants/role_constants";
import { checkAquiredRole } from "../../utils/Utils";
import { Button } from "reactstrap";

class Home extends Component {
  constructor(props) {
    super(props);

    this.props.onShowHeader();

    this.onSelectSavedBook = this.onSelectSavedBook.bind(this);
    this.onCreateProjectClick = this.onCreateProjectClick.bind(this);
    this.onClickGoToTopics = this.onClickGoToTopics.bind(this);
    //this.onClickGoToAccounts = this.onClickGoToAccounts.bind(this);
    this.onClickGenerateOnetimeCode = this.onClickGenerateOnetimeCode.bind(this);
    this.onClickClearOnetimeCode = this.onClickClearOnetimeCode.bind(this);
    this.childAccRef = createRef();
    this.props.onHideFavorite(false);
  }

  state = { isBtnHover: false, isHelpShown: false };

  onSelectSavedBook(_bookId) {
    this.props.onUpdateActiveSavedBook(_bookId);
    this.props.onToggleEditorMode();
  }

  onCreateProjectClick() {
    this.props.onNavigateToClientPage("/topics");
  }

  onHoverEffect = () => {
    this.setState((prevState) => ({ isBtnHover: !prevState.isBtnHover }));
  };

  showHelp = () => {
    this.setState((prevState) => ({ isHelpShown: !prevState.isHelpShown }));
  };

  onClickGoToTopics() {
    this.props.onNavigateToClientPage("/topics");
  }

  onClickGoToAccounts(isCreate) {
    this.props.onNavigateToClientPage(`/user/childaccounts?create=${isCreate}`);
  }

  onClickGenerateOnetimeCode() {
    this.props.requestOneTimeCode();
    this.props.onHideHeader();
  }

  async onClickClearOnetimeCode() {
    this.props.clearOnetimeCode();
    await this.props.onShowHeader();

    window.scrollTo({
      top: this.childAccRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  }

  render() {
    const {
      templateCatalouge,
      isLoggedIn,
      requestChangeFavouriteTemplate,
      requestTemplateDetails,
      savedBooks,
      onNavigateToClientPage,
      user,
      auth,
      onetimeCode,
    } = this.props;
    var favouriteCount = 0;

    var savedBookCount = savedBooks != null && savedBooks.length > 0 ? savedBooks.length : 0;

    const isChild = checkAquiredRole([ROLE_TYPE_CHILD], auth);
    const isParentOrTeacher = checkAquiredRole([ROLE_TYPE_USER, ROLE_TYPE_TEACHER], auth);
    const isParent = checkAquiredRole([ROLE_TYPE_USER], auth);
    const isTeacher = checkAquiredRole([ROLE_TYPE_TEACHER], auth);

    const { isBtnHover } = this.state;
    const { isHelpShown } = this.state;

    var createProjectPlaceHolders = [];
    if (savedBookCount > 0 && savedBookCount < 3) {
      for (var i = savedBookCount; i < 3; i++) {
        createProjectPlaceHolders.push(
          <div key={i} className="empty-topic-card">
            <div className="empty-topic-card-content">
              <div className="empty-topic-card-content__image">{/* <i className="fa-light fa-circle-plus fa-fw"></i> Create Project */}</div>

              <div className="empty-topic-card-desc">
                <h5 className="empty-topic-card-desc__title">My project</h5>
                <p className="empty-topic-card-desc__text">
                  {" "}
                  Written by <strong>Author name</strong>
                </p>
                <button className="btn btn--sml btn--secondary" onClick={this.onCreateProjectClick}>
                  Browse topics
                </button>
              </div>
            </div>
          </div>
        );
      }
    }

    var features = [];

    // features.push({
    //     id: 1,
    //     cover_image_url: monsters_bg,
    //     title: "Tooth and Claw!",
    //     subtitle: `Check out our feautured topic - Monsters.
    //     A truly terrifying topic about all things monsterous!
    //     Write about scary things that go bump in the night!`

    // })

    if (templateCatalouge !== null) {
      for (var j = 0; j < templateCatalouge.length; j++) {
        if (templateCatalouge[j].favourite === true) {
          favouriteCount++;
        }
        if (templateCatalouge[j].featured === true) {
          features.push({
            id: templateCatalouge[j].id,
            cover_image_url: templateCatalouge[j].cover_image_url,
            title: templateCatalouge[j].title,
            subtitle: templateCatalouge[j].subtitle,
          });
        }
      }
    }

    var favouritePlaceHolders = [];
    // if (favouriteCount < 3) {
    //   for (var k = favouriteCount; k < 3; k++) {
    //     favouritePlaceHolders.push(
    //       <Link key={k} className="empty-topic-card" to="/topics">
    //         <div className="empty-topic-card-content">
    //           <div className="empty-topic-card-content__image">{/* <i className="fa-light fa-heart fa-fw"></i> Add Favourite */}</div>

    //           <div className="empty-topic-card-desc">
    //             <h5 className="empty-topic-card-desc__title">My favorite topics</h5>
    //             <p className="empty-topic-card-desc__text">Browse through our topics templates, find your favourites and keep them here.</p>
    //           </div>
    //         </div>

    //         <div className="empty-topic-card-footer">
    //           <button className="btn btn--sml btn--secondary">Browse topics</button>
    //         </div>
    //       </Link>
    //     );
    //   }
    // }

    return (
      <div>
        {isLoggedIn === true ? (
          /* Logged in user */

          <div>
            {onetimeCode ? (
              <div className="login-block">
                <div className="login-block-card">
                  <div className="login-card-content">
                    <div>
                      <div className="login-card-content__icon">
                        <i className="fa-light fa-lock"></i>
                      </div>
                      <h2 className="login-card-content__code">{onetimeCode}</h2>
                      <p className="login-card-content__text">Share this code with your children to allow them to login with their username</p>
                    </div>
                    <button className="btn btn--primary" type="button" onClick={this.onClickClearOnetimeCode}>
                      {" "}
                      Continue{" "}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* SLIDER BLOCK */}

                <div className="slider-block">
                  <div className="slider-block__content">
                    <Carousel
                      className="slider"
                      emulateTouch={true}
                      infiniteLoop={true}
                      interval={3000}
                      autoPlay={true}
                      stopOnHover={true}
                      centerMode={false}
                      centerSlidePercentage={33.33}
                      showArrows={true}
                      showThumbs={false}
                      showIndicators={true}
                      showStatus={false}
                    >
                      {features.map((feat, i) => {
                        return (
                          <div key={i} className="slider__large-slide">
                            <Link className="slider__link" to={`/topics/${feat.id}/details`}>
                              <img src={feat.cover_image_url} />
                            </Link>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>

                {/* CURRENT PROJECTS BLOCK */}

                <div id="my-projects" className="section-block">
                  <div className="section-block-content">
                    <h2 className="section-block-content__title">Current projects</h2>

                    {savedBookCount < 1 ? (
                      <div>
                        <p className="project-card-desc__title">You currently have no saved projects</p>
                        <p className="project-card-desc__text">
                          {isParent
                            ? "Once your child begins their writing journey, you can view and edit their saved projects here. Start by creating an account for your child following the instructions below."
                            : isTeacher
                            ? "Once your class begin their writing journey, you can view and edit their saved projects here. Start by creating each child in your class an account following the instructions below."
                            : " Once you begin your writing journey, your saved projects will be shown here. There’s lots of fun topics to choose from, so what are you waiting for? Get started now and find a topic that sparks your imagination."}
                        </p>
                        <button className="btn btn--sml btn--success" onClick={this.onClickGoToTopics.bind()}>
                          Browse Topics
                        </button>
                      </div>
                    ) : (
                      <p className="section-block-content__text">This is where your current topic projects are shown.</p>
                    )}

                    <div className="section-block-content__grid">
                      {savedBookCount > 0
                        ? savedBooks.map((e, i) => {
                            return (
                              <ProjectItem
                                key={i}
                                project={e}
                                onSelectSavedBook={this.onSelectSavedBook}
                                onNavigateToClientPage={onNavigateToClientPage}
                                getRequestHeaders={this.props.getRequestHeaders}
                                isChild={isChild}
                                isParentOrTeacher={isParentOrTeacher}
                                requestSavedBooks={this.props.requestSavedBooks}
                              />
                            );
                          })
                        : null}

                      {createProjectPlaceHolders}
                    </div>

                    {/* <div className="help-container mt-4 ml-1">

                            <button className="filter-btn font-weight-bold" onClick={this.showHelp}>
                                <i className="fa-solid fa-circle-question"></i>Show help
                            </button>

                            <div className={`help-content-container hide ${isHelpShown ? "show alert alert-success text-dark mt-3" : ""}`}>
                                <p className="ptop-xs no-spc t-sml t-bld py-1">
                                    This area shows your current projects. You can edit these templated by clicking <span className="t-medium t-hvy">'Edit Project'</span>{" "}
                                    button. You can{" "}
                                    <Link to="/topics" className="font-weight-bold text-dark link-text">
                                    browse topics
                                    </Link>{" "}
                                    to find a template you wish to use. Once you find a template, simply click the <span className="t-medium t-hvy">'Save to Home'</span>{" "}
                                    button and your project will appear above.
                                </p>
                            </div>
                        </div> */}
                  </div>
                </div>

                {/* CHILD ACCOUNTS BLOCK */}

                {isChild === false ? (
                  <div className="section-block section-block--grey-xl">
                    <div className="section-block-content">
                      <h2 className="section-block-content__title">Child accounts</h2>
                      {user !== null && user.children && user.children.length > 0 ? (
                        <p className="section-block-content__text">
                          You can see the list of child accounts here, you can add another account or edit the existing accounts by clicking manage accounts.
                        </p>
                      ) : (
                        <div>
                          <p className="section-block-content__text">You've not created a child account yet, to get started, click 'Create Account'.</p>
                          <button className="btn btn--primary" type="button" onClick={this.onClickGoToAccounts.bind(this, false)}>
                            Create account
                          </button>
                        </div>
                      )}

                      <div className="child-account-block__grid">
                        {user !== null && user.children !== undefined && user.children.length > 0 ? (
                          <div>
                            {user.children.map((e, i) => {
                              return <ChildAccountItem key={i} child={e} onNavigateToClientPage={this.props.onNavigateToClientPage} allowEdit={false} />;
                            })}

                            <button className="mt-4 btn btn--primary" type="button" onClick={this.onClickGoToAccounts.bind(this, true)}>
                              Create account
                            </button>
                          </div>
                        ) : null}
                      </div>

                      {user.children.length > 0 && (
                        <div className="passcode-block mt-5" ref={this.childAccRef}>
                          <div className="passcode-block-content">
                            <div className="passcode-block-content__decr">
                              <h2 className="passcode-block-content__title">Generate passcode</h2>
                              <p className="passcode-block-content__text">
                                {isTeacher
                                  ? "To allow your class to access Topic Heroes, you will need to generate a code for all children to use with their username when logging in. Hit the ‘Generate code’ button to create an access code for them to login with. This code will remain the same unless you generate a new one"
                                  : isParent
                                  ? "To allow your child to access Topic Heroes, you will need to generate a code for them to use with their username when logging in. Hit the button and create an access code for them to login with. This code will remain the same unless you generate a new one."
                                  : "You need to generate a code for children to use when logging in. Hit the button and create an access code for them to login with."}
                              </p>
                            </div>

                            <div className="child-account-actions">
                              <button className="btn btn--success" onClick={this.onClickGenerateOnetimeCode}>
                                Generate code
                              </button>
                              <button className="btn btn--primary" onClick={this.onClickGoToAccounts.bind(this, false)}>
                                Manage accounts
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ) : null}

                {/* FAVOURITES BLOCK */}

                <div className="section-block" ref={this.props.favRef}>
                  <div className="section-block-content">
                    <h2 className="section-block-content__title">My favourites</h2>

                    <p className="section-block-content__text">These are your favourite topics, all in one place!</p>

                    <div className="section-block-content__grid">
                      {templateCatalouge.map((e, i) => {
                        if (e.favourite === false) {
                          return null;
                        }
                        favouriteCount++;
                        return (
                          <TemplateItem
                            key={i}
                            template={e}
                            isLoggedIn={isLoggedIn}
                            onNavigateToClientPage={onNavigateToClientPage}
                            requestTemplateDetails={requestTemplateDetails}
                            requestChangeFavouriteTemplate={requestChangeFavouriteTemplate}
                          />
                        );
                      })}

                      {favouritePlaceHolders}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ) : (
          /* Logged out user */

          <div>
            <div className="hero-banner-block hero-banner-block--fh">
              <img className="hero-banner-block__image" src={space_bg} />

              <img className="hero-banner-block__spaceman" src={astronaught} />

              <div className={`hero-banner-block__moon ${isBtnHover ? "hero-banner-block__moon--focus" : ""}`}>
                <img src={moon} />
              </div>

              <div className="hero-banner-content" onMouseEnter={this.onHoverEffect} onMouseLeave={this.onHoverEffect}>
                <h1 className="hero-banner-content__title">We're on a mission</h1>
                <p className="hero-banner-content__text">
                  Join our national campaign to make Topic Heroes<sup>®</sup> available FREE to every Primary School aged child in the UK. We're calling for
                  business, teachers, parents and delivery partners to get involved in helping make this a reality.
                </p>
                <div className="hero-banner-content__buttons">
                  <Link className="btn btn--primary-reverse" to="/register">
                    Register
                  </Link>
                  <p>OR</p>
                  <Link className="btn btn--primary-reverse" to="/Login">
                    Sign in
                  </Link>
                </div>
              </div>
            </div>

            {/* Testing Section */}

            {/* Browse Topics Section */}

            {/* <section className="bgc-grade-pink">
              <div className="p-row">
                <div className="d-grid col-2-1-1">
                  <div className="p-col d-flex flex-col flex-jc-c">
                    <h2 className="hw-font t-white spc-b-r">Putting the joy back into writing</h2>
                    <p className="t-white spc-b-l">
                      Topic Heroes<sup>®</sup> has been developed to capture and ignite children's imagination and get them excited about writing through
                      topic-based learning. It's beautifully simple too. There's an extensive range of topic themes available from sport, healthy eating and
                      friendship to dinosaurs, climate change, mysteries, monsters, holidays and pets. Once children have selected a topic, they are ready to
                      have fun and get learning.
                    </p>
                    <Link className="th-btn yellow-btn" to="/topics">
                      Browse Topics
                    </Link>
                  </div>

                  <div>
                    <img className="th-img-contain" src={thGroupImage} />
                  </div>
                </div>
              </div>
            </section> */}

            {/* <div className="p-row bgc-pattern" style={{ backgroundImage: `url(${pattern})` }}>
              <div className="content-mw pall-l">
                <div className="d-grid col-2-1-1 th-gap-l">
                  <div>
                    <img className="th-img-fit th-img-mw" src={joy} />
                  </div>

                  <div className="d-flex flex-col flex-ai-l flex-jc-c">
                    <h3 className="hw-font spc-b-s">Putting the joy back into writing</h3>

                    <p className="spc-b-l mw-480">
                      Topic Heroes<sup>®</sup> has been developed to capture and ignite children’s imagination and get them excited about writing through
                      topic-based learning. It’s beautifully simple too. There’s an extensive range of topic themes available from sport, healthy eating and
                      friendship to dinosaurs, climate change, mysteries, monsters, holidays and pets. Once children have selected a topic, they are ready to
                      have fun and get learning.
                    </p>

                    <Link className="th-btn yellow-btn" to="/Register">
                      Register
                    </Link>
                  </div>

                  <div className="d-flex flex-col flex-ai-l flex-jc-c">
                    <h3 className="hw-font spc-b-s">Inspiring children to write</h3>

                    <p className="spc-b-l">
                      Each topic offers a great mix of writing style templates, in-line with National Curriculum, such as a newspaper article, postcard, poem,
                      chronological report or autobiography, all proven to inspire children’s appetite for writing.
                    </p>

                    <Link className="th-btn yellow-btn" to="/topics">
                      Explore Topics
                    </Link>
                  </div>

                  <div>
                    <img className="th-img-fit th-img-mw" src={inspire} />
                  </div>

                  <div>
                    <img className="th-img-fit th-img-mw" src={book} />
                  </div>

                  <div className="d-flex flex-col flex-ai-l flex-jc-c">
                    <h3 className="hw-font spc-b-s">And now to reveal the twist</h3>

                    <p className="spc-b-l">
                      Topic Heroes<sup>®</sup> has a unique surprise up its sleeve. Every child that completes a topic is rewarded with their very own authored,
                      professionally printed book to celebrate their hard work and to enjoy with family and friends!
                    </p>

                    <Link className="th-btn yellow-btn" to="/Register">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="p-row bgc-grade-sunshine">
              <div className="content-mw pall-l">
                <div className="d-flex flex-col flex-ai-c flex-jc-c">
                  <h2 className="hw-font t-ctr spc-b-s">Innovative learning</h2>

                  <p className="t-ctr spc-b-l mw-480">
                    We’re on a mission to provide Topic Heroes<sup>®</sup> free to every primary school in the UK. Your school is only one step away from taking
                    advantage of this innovative and rewarding learning resource.
                  </p>

                  <Link className="th-btn purple-btn" to="/register/parent">
                    Register
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <div className="p-row bgc-white">
              <div className="content-mw pall-l">
                <div className="d-flex flex-col flex-ai-c flex-jc-c">
                  <h2 className="hw-font t-ctr spc-b-s mw-660">
                    A physical book provides more impact, more variety, promotes sharing with others and creates lasting memories to be kept at school or at
                    home.
                  </h2>
                </div>
              </div>
            </div> */}

            {/* <div className="p-row bgc-grade-dusk">
              <div className="content-mw pall-l">
                <div className="d-flex flex-col flex-ai-c flex-jc-c">
                  <h2 className="hw-font t-white t-ctr spc-b-s">Why let schools have all the fun?</h2>

                  <p className="t-ctr t-white spc-b-l mw-480">
                    Topic Heroes<sup>®</sup> is perfect for children to get excited about writing at home too. The whole family can get involved with topics and
                    be more inspired with literacy at home.
                  </p>

                  <Link className="th-btn yellow-btn" to="/register/teacher">
                    Register
                  </Link>
                </div>
              </div>
            </div> */}

            {/* <div className="p-row bgc-grade-snowfall">
              <div className="content-mw pall-l">
                <h2 className="hw-font spc-b-s t-ctr">Help inspire the next generation</h2>
                <p className="t-ctr mw-660 spc-b-xl">
                  UK Business has a part to play in enhancing economic, environmental and societal conditions. Topic Heroes<sup>®</sup> provides a unique
                  opportunity for you to help children improve their literacy skills and enjoy a much brighter future.
                </p>

                <div className="d-grid col-3-2-1 th-gap-l">
                  <div className="bgc-white th-br th-shadow-down pall-l d-flex flex-col flex-jc-sb flex-ai-c th-gap-l">
                    <div className="d-flex flex-col flex-ai-c th-gap-r">
                      <img className="card-icon-image" src={sponsorshipIcon} />
                      <h3 className="hw-font t-ctr">Sponsorship</h3>
                      <p className="t-ctr">
                        We are looking for companies to support this literacy initiative by becoming a headline, category or topic sponsors.
                      </p>
                    </div>

                    <div>
                      <a href="https://topicheroes.co.uk/sponsorship/" target="_blank">
                        <button className="th-btn yellow-btn" To="https://topicheroes.co.uk/sponsorship/" target="_blank">
                          Read more
                        </button>
                      </a>
                    </div>
                  </div>

                  <div className="bgc-white th-br th-shadow-down pall-l d-flex flex-col flex-jc-sb flex-ai-c th-gap-l">
                    <div className="d-flex flex-col flex-ai-c th-gap-r">
                      <img className="card-icon-image" src={deliveryIcon} />
                      <h3 className="hw-font t-ctr">Delivery partners</h3>
                      <p className="t-ctr">A powerful CSR programme that’s impacting children’s lives and helping create change.</p>
                    </div>

                    <div>
                      <button className="th-btn yellow-btn" To="https://topicheroes.co.uk/delivery-partners/" target="_blank">
                        Read more
                      </button>
                    </div>
                  </div>

                  <div className="bgc-white th-br th-shadow-down pall-l d-flex flex-col flex-jc-sb flex-ai-c th-gap-l">
                    <div className="d-flex flex-col flex-ai-c th-gap-r">
                      <img className="card-icon-image" src={publicityIcon} />
                      <h3 className="hw-font t-ctr">Publicity partners</h3>
                      <p className="t-ctr">Help us spread the word and make schools aware of this FREE creative learning resource.</p>
                    </div>

                    <div>
                      <button className="th-btn yellow-btn" To="https://topicheroes.co.uk/publicity-partners/" target="_blank">
                        Read more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        )}
      </div>
    );
  }
}

export default Home;

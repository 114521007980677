import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { toggleSearchFilterOptionChange, toggleSearchFilterOptionChangeByTitle } from "../../utils/Utils";
import SearchFilterOptionList from "./SearchFilterOptionList";

class SearchBar extends Component {


    constructor(props) {
      super(props);

      this.state = { 
        filtersVisible: false
      };

      this.onClickToggleFilters = this.onClickToggleFilters.bind(this);
      this.onSubmitSearch = this.onSubmitSearch.bind(this);

      this.onSelectedOptionChange = this.onSelectedOptionChange.bind(this);
      this.onSelectedOptionDropdownChange = this.onSelectedOptionDropdownChange.bind(this);
      this.onClickResetFilters = this.onClickResetFilters.bind(this);

      //this.renderFilterSelection = this.renderFilterSelection.bind(this);
    }

    onClickToggleFilters() {
      var toggledVal = !this.state.filtersVisible;

      this.setState({
        filtersVisible: toggledVal
      });
    }

    onSubmitSearch(_e) {

      //console.log("onSubmitSearch");

      _e.preventDefault();

      // var searchLengthValid = this.props.searchTerm.length > 0;
      // if(searchLengthValid) {
      //   this.props.onSubmitSearch();
      // }
      this.props.onSubmitSearch();
      
    }

    onSelectedOptionChange(_optionId) {

      //console.log("onSelectedOptionChange: ", _optionId);

      var { searchFilterCatalouge } = this.props;
      toggleSearchFilterOptionChange(searchFilterCatalouge, _optionId);
      this.props.onUpdateSearchFilterCatalouge(searchFilterCatalouge);
    }

    onSelectedOptionDropdownChange(_sfid, _e) {

      const selectedValue = _e.target.value;
      var { searchFilterCatalouge } = this.props;
      toggleSearchFilterOptionChangeByTitle(searchFilterCatalouge, selectedValue, _sfid);
      this.props.onUpdateSearchFilterCatalouge(searchFilterCatalouge);
    }

    // renderFilterSelection(searchFilterCatalouge) {

      
    //   const formGroups = searchFilterCatalouge.map((e, i) => {
    //     const isMultiSelect = e.multiselect && e.multiselect === true;

    //     var selectControl = null;

        
    //     var selectOption = "";

    //     if(isMultiSelect === false) {
    //       for(var oj = 0; oj < e.options.length; oj++) {
    //         if(e.options[oj].selected === true) {
    //           selectOption = e.options[oj].title;
    //         }
    //       }
    //     }

    //     selectControl = (
    //       <select className="control" placeholder={e.title} onChange = { this.onSelectedOptionDropdownChange.bind(this, e.id) } value={ selectOption } >
    //         {
    //           e.options.map((opt, j) => {
    //             return (
    //               <option key={j} value={opt.title} >{ opt.title }</option>
    //             )
    //           })
    //         }
    //       </select>
    //     );

    //     const isEven = i % 2 === 0;
    //     return (
    
    //       <FormGroup className="th-field-wrapper"  key={e.id}>
    //         <Label
    //           for={e.title}
    //         >
    //           {e.title}
    //         </Label>

    //           {
    //             (isMultiSelect === true) ? (
    //               e.options.map((opt, j) => {
    //                 return (
    //                   <FormGroup check key={opt.id}>
    //                       <Input
    //                         id={"option_" + opt.id}
    //                         type="checkbox"
    //                         value = { opt.selected }
    //                         onChange = { this.onSelectedOptionChange.bind(this, opt.id) }
    //                       />
    //                       {' '}
    //                       <Label check>
    //                         { opt.title }
    //                       </Label>
    //                   </FormGroup>
    //                 );
    //               })
    //             ) : ( selectControl )

    //           }
    //       </FormGroup>

    //     );
    //   });

    //   return (
    //     <form onSubmit = { this.onSubmitSearch }
    //       // onKeyPress={(e) => {
    //       //   console.log("form key press");
    //       //   if (e.key === "Enter") {
    //       //     this.onSubmitSearch();
    //       //   }
    //       // }}
    //       className = "filter-container"> 
    //       {formGroups}
    //       <div className="th-form-buttons">
    //           <button type="submit" className="th-btn icon-btn yellow-btn d-flex flex-ai-c">
    //             <i className="fa-light fa-magnifying-glass"></i>Search
    //           </button>
    //       </div>
    //     </form>
    //   );
    // }

    onClickResetFilters() {
      this.props.requestSearchFilterCatalouge();
      this.setState({
        filtersVisible: false
      });
    }

    render() {
        var visibility = "hide";
     
        if (this.props.searchVisibility) {
          visibility = "show";
        }

        const { filtersVisible } = this.state;
        const { searchTerm, searchFilterCatalouge } = this.props;
        const filterBtnText = (filtersVisible === false) ? "Show" : "Hide";
        
        return (
          <div className={`app-search-bar ${visibility}`}>
            <form onSubmit={ this.onSubmitSearch }>
              <div className="app-search-bar__content">
              
                  <div className='app-field-wrapper'>
                    <label>Search Topics</label>
                    <input type="text" value={ searchTerm } placeholder="Search Topics" onChange={ this.props.onSearchTermChange } />
                  </div>
                  {
                    (filtersVisible === false) ? (     
                      <button type="submit" className="btn btn--sml btn--primary" ><i className="fa-light fa-magnifying-glass"></i>Search</button>
                    ) : null
                  }
              
              </div>
            </form>
            <div className={'app-search-filters'}>
              <div className={'app-search-filters__controls'}>
                <button className={"btn-filter"} onClick={ this.onClickToggleFilters }><i className="fa-light fa-filter fa-fw"></i>{filterBtnText} filters</button>
                <button className={"btn-filter"} onClick={ this.onClickResetFilters }><i className="fa-light fa-arrow-rotate-right"></i>Reset filters</button>
               </div>

              {
                (filtersVisible === true) ? (
                  <form onSubmit = { this.onSubmitSearch }
                    className = "app-search-filters"> 
                      <SearchFilterOptionList 
                        searchFilterCatalouge = { searchFilterCatalouge } 
                        onSelectedOptionChange = { this.onSelectedOptionChange }
                        onSelectedOptionDropdownChange = { this.onSelectedOptionDropdownChange } />
                    <div className="th-form-buttons">
                        <button type="submit" className="btn btn--sml btn--primary">
                          <i className="fa-light fa-magnifying-glass"></i>Search
                        </button>
                    </div>
                  </form>
                  
                ) : null
              }
            </div>
           
          </div>
        );
      }
}
 
export default SearchBar;

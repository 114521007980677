export const defaultAddress = {
  id: 0,
  addline_1: "",
  addline_2: "",
  city: "",
  county: "",
  country: "",
  postcode: "",
  primary: false,
};

export const defaultChildDetail = {
  id: "",
  first_name: "",
  last_name: "",
  username: "",
  password: "",
  old_password: "",
};

export const defaultSponsor = {
  id: "",
  title: "",
  website: "",
};

export const defaultSchool = {
  id: 0,
  title: "",
};

export const defaultSubject = {
  id: 0,
  title: "",
  image_url: "",
};

const itemSlides1 = [
  {
    altText: "Church1",
    caption: "Church1",
    key: 1,
    src: "https://picsum.photos/id/782/600/300",
  },
  {
    altText: "Church2",
    caption: "Church2",
    key: 2,
    src: "https://picsum.photos/id/508/600/300",
  },
];

const itemSlides2 = [
  {
    altText: "Rainbow Sky",
    caption: "Rainbow Sky",
    key: 1,
    src: "https://picsum.photos/id/724/600/300",
  },
  {
    altText: "Rainbow",
    caption: "Rainbow",
    key: 2,
    src: "https://picsum.photos/id/703/600/300",
  },
  {
    altText: "Rainbow Water",
    caption: "Rainbow Water",
    key: 7,
    src: "https://picsum.photos/id/1035/600/300",
  },
];

const itemSlides3 = [
  {
    altText: "Jets",
    caption: "Jets",
    key: 4,
    src: "https://picsum.photos/id/383/600/300",
  },
];

const itemSlides4 = [
  {
    altText: "Water",
    caption: "Water",
    key: 1,
    src: "https://picsum.photos/id/1053/600/300",
  },
  {
    altText: "Water 2",
    caption: "Water 2",
    key: 3,
    src: "https://picsum.photos/id/1069/600/300",
  },
];

const itemSlides5 = [
  {
    altText: "Vintage",
    caption: "Vintage",
    key: 1,
    src: "https://picsum.photos/id/111/600/300",
  },
];

const itemSlides6 = [
  {
    altText: "Father",
    caption: "Father",
    key: 6,
    src: "https://picsum.photos/id/1001/600/300",
  },
];

export const topics = [
  {
    id: 1,
    title: "Awesome Autumn",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides1,
    favourite: false,
  },
  {
    id: 2,
    title: "Healthy Eating",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides2,
    favourite: false,
  },
  {
    id: 3,
    title: "Mystery Story",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides3,
    favourite: false,
  },
  {
    id: 4,
    title: "Pets",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides4,
    favourite: false,
  },
  {
    id: 5,
    title: "Space Adventure",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides5,
    favourite: false,
  },
  {
    id: 6,
    title: "Spring",
    description: "A brief topic description",
    subtitle: "Key stage 1",
    images: itemSlides6,
    favourite: false,
  },
];

export const PRINTESS_EDITOR_URL = "https://editor.printess.com/v/nightly/printess-editor/embed.html";

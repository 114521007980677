import React, { Component } from 'react';
import axios from '../../apis/thresource';
import { useParams } from 'react-router';

class ForgotPassword extends Component {

    constructor(props){
        super(props);
         this.props.onHideHeader();

        this.state = {
            email: "",
            emailSent:false
        };

        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onChangeEmail(_e){
        this.setState({
            email: _e.target.value
        });
    }


    onSubmitForm(_e){
        _e.preventDefault();

        const postData = {
            email: this.state.email
        };

        axios.post('/api/auth/forgot-password', JSON.stringify(postData))
        .then(response => {
            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }
            if(response.data.success) {
                  this.setState({
                        emailSent: true
                    });
                // if(response.data.data.is2FAEnabled){
                //     const user = {
                //         email:response.data.data.email,
                //         mobile: response.data.data.mobile,
                //         resetToken: response.data.data.resetToken
                //     }
                //     this.props.onUpdateUser(user);
                //     this.props.history.push('/verify-2fa');
                // }else{
                //     this.setState({
                //         emailSent: true
                //     });
                // }
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                //console.log("server data:", error.response.data);
                this.props.onSetAlert(error.response.data.errors);
            }
        });
    }


    render() {

        const { email,emailSent } = this.state;
      

        return (
            <div className='login-block'>
               {!emailSent ?
                    <form className="login-block-card" onSubmit={ this.onSubmitForm } >

                        <div className="login-card-content">

                            <div>
                                <div className='login-card-content__icon'><i className="fa-light fa-lock"></i></div>
                                    <h2 className="login-card-content__title">Password Reset</h2>
                                    <p className="login-card-content__text">Enter the registered email address and we'll send you reset link to change your password.</p>
                                
                            </div>
                            
                            <div className='login-card-content__form'>
                                <div className='app-field-wrapper'>
                                    <label>Email Address</label>
                                    <input autoComplete="on" name="email" type="text" maxLength="128" placeholder="yourname@domain.co.uk" onChange={ this.onChangeEmail } value={ email } />
                                </div>

                                <div className="app-field-wrapper__desc">
                                    <label>If you have 2FA enabled, we'll send you a code.</label>
                                </div>
                            </div>

                            <button className="btn btn--primary-fw" type="submit"> Continue </button>
                            
                        </div>  
                        

                    </form> :

                   <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">
                        <div className='th-form-inner'>
                            <h2 className="hw-font t-purple t-ctr">Link sent</h2>
                            <p className="t-grey t-ctr"> An email has been sent to { email }. Follow the link in the email to reset password.</p>
                        </div>
                    </div>

               }
               
            </div>
        );
    }
}

export default ForgotPassword;



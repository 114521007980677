import React, { Component } from "react";
//import { NavLink as RRNavLink, Link } from 'react-router-dom';
import Loader from "../../components/Loader";
import { RESOURCE_TYPE_GLOSSARY, RESOURCE_TYPE_WORKBOOK } from "../../constants/string_constants";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
// import axios from '../../apis/thresource';
import _ from "lodash";
//import Modal from "react-modal";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "../../apis/thresource";
import { checkAquiredRole } from "../../utils/Utils";
import { ROLE_TYPE_CHILD } from "../../constants/role_constants";

class TopicDetail extends Component {
  constructor(props) {
    super(props);
    this.props.onShowHeader();

    const project = this.props.savedBooks.find((x) => x.template_id == this.props.templateDetail?.id);

    this.state = {
      isOpenImageBox: false,
      photoIndex: 0,
      showDeleteConfirm: false,
      isCompleted: project ? project.completed : false,
      isChild: checkAquiredRole([ROLE_TYPE_CHILD], this.props.auth),
    };

    this.onHandleSaveBookClick = this.onHandleSaveBookClick.bind(this);
    this.onHandleDeleteBookClick = this.onHandleDeleteBookClick.bind(this);
    this.onHandleFavouritesClick = this.onHandleFavouritesClick.bind(this);
    this.onHandleStartTopic = this.onHandleStartTopic.bind(this);
    this.findAndAssignMatchingBook = this.findAndAssignMatchingBook.bind(this);
    this.navigateToRegister = this.navigateToRegister.bind(this);
    this.onSaveToHomeAndEditTopic = this.onSaveToHomeAndEditTopic.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.props.onHideFavorite(true);
  }

  componentDidMount() {
    if (this.props.templateCatalouge === null) {
      this.props.requestTemplateCatalouge();
    }

    //if (this.props.templateDetail === null) {
    const templateId = parseInt(this.props.match.params.id, 10);
    this.props.requestTemplateDetails(templateId);
    //}
  }

  // shouldComponentUpdate(nextProps, nextState) {
  //     if (nextProps.templateDetail !== this.props.templateDetail) {
  //         return true;
  //     }
  //     else if (nextProps.templateDetail.id !== this.props.templateDetail.id) {
  //         return true;
  //     }
  //     else {
  //         return false;
  //     }
  // }

  onHandleSaveBookClick() {
    this.props.requestCreateSavedBook();
    this.props.onNavigateToClientPage("/");
  }

  onHandleDeleteBookClick() {
    this.setState({
      showDeleteConfirm: true,
    });
  }

  onConfirmDelete() {
    this.closeModal();
    var bookId = this.findAndAssignMatchingBook();
    this.props.requestDeleteSavedBook(bookId);
    //TODO now load the saved book in the editor.
  }

  closeModal() {
    this.setState({
      showDeleteConfirm: false,
    });
  }

  onHandleFavouritesClick() {
    const { templateDetail } = this.props;
    this.props.requestChangeFavouriteTemplate(templateDetail.id);
  }

  findAndAssignMatchingBook() {
    const { templateDetail, savedBooks } = this.props;

    var bookId = 0;
    for (var i = 0; i < savedBooks.length; i++) {
      if (savedBooks[i].template_id === templateDetail.id) {
        bookId = savedBooks[i].id;
        break;
      }
    }
    return bookId;
  }

  onHandleStartTopic() {
    const { templateDetail, savedBooks } = this.props;
    var template_id = 0;
    for (var i = 0; i < savedBooks.length; i++) {
      if (savedBooks[i].template_id === templateDetail.id) {
        template_id = savedBooks[i].id;
        break;
      }
    }

    this.props.onSelectStartWriting(template_id);
  }

  onSaveToHomeAndEditTopic() {
    const { templateDetail, savedBooks } = this.props;
    const postData = {
      template_id: templateDetail.id,
    };
    axios
      .post("/api/savedbooks/create", postData, this.props.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.props.requestSavedBooks();

          setTimeout(() => {
            this.onHandleStartTopic();
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onHandleDownloadResource(_resource) {
    window.open(_resource.resource_url, "_blank");
  }

  onHandleRelatedTemplateClick(_templateId) {
    this.props.requestTemplateDetails(_templateId);
    this.props.onNavigateToClientPage(`/topics/${_templateId}/details`);
  }

  navigateToRegister() {
    this.props.onNavigateToClientPage("/Register");
  }

  render() {
    const { isLoggedIn, templateDetail, templateCatalouge } = this.props;
    const { isOpenImageBox, photoIndex, showDeleteConfirm, isChild, isCompleted } = this.state;

    if (templateDetail === null) {
      return <Loader />;
    }

    var bookExists = this.findAndAssignMatchingBook() > 0;

    const favouriteButtonText = templateDetail.favourite === true ? "Remove from favourites" : "Add to favourites";
    const saveBookButtonText = bookExists === false ? "Save to home" : "Delete saved book";

    var imagesCount = 0;
    const lightBoxImages = [];

    for (var i = 0; i < templateDetail.images.length; i++) {
      if (templateDetail.images[i].image_url !== templateDetail.cover_image_url && templateDetail.images[i].image_url !== templateDetail.thumbnail_url) {
        imagesCount++;
        lightBoxImages.push(templateDetail.images[i]);
      }
    }

    var imgGalleryPlaceHolders = [];
    if (imagesCount < 6) {
      for (var j = imagesCount; j < 6; j++) {
        imgGalleryPlaceHolders.push(
          <div key={j} className="d-flex flex-col flex-jc-c flex-ai-c bgc-light-grey ar-6x4 th-br">
            <p className="t-grey">
              <i className="fa-light fa-image"></i>
            </p>
          </div>
        );
      }
    }

    const eligibleOtherCatalogs = templateCatalouge.filter((x) => x.id != +this.props.match.params.id);
    const templatesCount = eligibleOtherCatalogs.length;
    var otherTopicPlaceHolders = [];
    if (templatesCount > 0) {
      while (otherTopicPlaceHolders.length < 6) {
        var randomIndex1 = Math.floor(Math.random() * templatesCount);
        const imgSrc = eligibleOtherCatalogs[randomIndex1].thumbnail_url;
        if (imgSrc) {
          otherTopicPlaceHolders.push({
            id: eligibleOtherCatalogs[randomIndex1].id,
            element: (
              <div
                key={eligibleOtherCatalogs[randomIndex1].id}
                className="related-slider__slide-sml"
                onClick={this.onHandleRelatedTemplateClick.bind(this, eligibleOtherCatalogs[randomIndex1].id)}
              >
                <img src={`${imgSrc}`} />
              </div>
            ),
          });
        }
      }
    }

    const uniqueCatalogs = _.uniqBy(otherTopicPlaceHolders, "id");
    const relatedTemplates = uniqueCatalogs.map((x) => x.element);

    // const relatedCaroselItems1 = templateCatalouge[randomIndex1].images.map((rc1, rc1i) => {
    //     return ({
    //         altText: templateCatalouge[randomIndex1].title,
    //         caption: templateCatalouge[randomIndex1].title,
    //         key: templateCatalouge[randomIndex1].id + "_" + rc1i,
    //         src: rc1.image_url
    //     });
    // });

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const downloadCount = templateDetail.resources !== null && templateDetail.resources.length > 0 ? templateDetail.resources.length : 0;

    return (
      <div>
        {templateDetail && (
          <>
            <div className="topic-banner-block">
              <div className="topic-banner-content">
                <div className="topic-banner-content__image">
                  <img src={templateDetail.cover_image_url} alt="" />
                </div>
              </div>
            </div>

            <div className="topic-info-block">
              <div className="topic-info-content">
                <div className="topic-info">
                  <h1 className="topic-info__title">{templateDetail.title}</h1>
                  <p className="topic-info__intro-text"> {templateDetail.subtitle} </p>
                  <p className="topic-info__text">{templateDetail.description}</p>

                  <h2 className="topic-info__subtitle">Template gallery</h2>

                  {lightBoxImages.length > 0 && isOpenImageBox && (
                    <Lightbox
                      mainSrc={lightBoxImages[photoIndex].image_url}
                      nextSrc={lightBoxImages[(photoIndex + 1) % lightBoxImages.length].image_url}
                      prevSrc={lightBoxImages[(photoIndex + lightBoxImages.length - 1) % lightBoxImages.length].image_url}
                      onCloseRequest={() => this.setState({ isOpenImageBox: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + lightBoxImages.length - 1) % lightBoxImages.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightBoxImages.length,
                        })
                      }
                    />
                  )}

                  <div className="topic-gallery">
                    {imagesCount > 0
                      ? templateDetail.images.map((tImg, j) => {
                          if (tImg.image_url === templateDetail.cover_image_url || tImg.image_url === templateDetail.thumbnail_url) {
                            return;
                          }

                          return (
                            <div
                              key={j}
                              className="topic-detail-image d-flex flex-col flex-jc-c flex-ai-c bgc-light-grey ar-6x4 th-br"
                              style={{ backgroundImage: `url(${tImg.image_url})`, backgroundSize: "cover" }}
                              onClick={() => this.setState({ isOpenImageBox: true })}
                            ></div>
                          );
                        })
                      : null}

                    {imgGalleryPlaceHolders}
                  </div>

                  {isLoggedIn === true ? (
                    <div className="templete-actions-block">
                      <div className="templete-actions-content">
                        {bookExists ? (
                          <button className="btn btn--success" onClick={this.onHandleStartTopic} disabled={isChild && isCompleted}>
                            Continue writing
                          </button>
                        ) : (
                          <button className="btn btn--success" onClick={this.onSaveToHomeAndEditTopic}>
                            Start Writing
                          </button>
                        )}

                        <div className="templete-actions-right">
                          {bookExists === false ? (
                            <button className="btn btn--secondary" onClick={this.onHandleSaveBookClick}>
                              {saveBookButtonText}
                            </button>
                          ) : (
                            <button className="btn btn--danger" onClick={this.onHandleDeleteBookClick}>
                              {saveBookButtonText}
                            </button>
                          )}

                          <button className="btn btn--secondary" onClick={this.onHandleFavouritesClick}>
                            {favouriteButtonText}
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="topic-resources">
                  <div>
                    <h2 className="topic-resources__title">Topic Resources</h2>
                    <p className="topic-resources__text">
                      We've created some fab resources for you to download for this topic. There are some handy hints and tips to help you write amazing books.
                    </p>
                  </div>
                  <div>
                    {isLoggedIn ? (
                      downloadCount > 0 && <p className="t-bld">Download the files</p>
                    ) : (
                      <div>
                        <h3 className="topic-resources__title">Innovative learning</h3>
                        <p className="topic-resources__text">
                          Topic Heroes<sup>®</sup> is perfect for children to get excited about writing. The whole family can get involved with topics and be
                          more inspired with literacy at school and at home. Register today and take advantage of this innovative and rewarding learning
                          resource.
                        </p>
                        <button class="btn btn--primary" onClick={this.navigateToRegister}>
                          Register now
                        </button>
                      </div>
                    )}

                    {isLoggedIn && (
                      <div className="d-flex flex-col th-gap-s flex-ai-l">
                        {downloadCount > 0
                          ? templateDetail.resources.map((tRsc, k) => {
                              var buttonGraphicName = "fa-list-check";
                              var buttonText = "Download";
                              if (tRsc.resource_type === RESOURCE_TYPE_GLOSSARY) {
                                buttonGraphicName = "fa-square-quote";
                                buttonText = "Glossary";
                              }
                              if (tRsc.resource_type === RESOURCE_TYPE_WORKBOOK) {
                                buttonGraphicName = "fa-list-check";
                                buttonText = "Worksheets";
                              }

                              return (
                                <button key={k} className="th-download-btn d-flex flex-ai-c" onClick={this.onHandleDownloadResource.bind(this, tRsc)}>
                                  <i className={`fa-light ${buttonGraphicName} fa-fw`}></i>
                                  {buttonText}
                                </button>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>

                  {templateDetail.sponsor_id ? (
                    <div className="sponsor-block">
                      <p className="sponsor-block__text">This topic is sponsored by:</p>

                      <h3 className="sponsor-block__sponsor">{templateDetail?.sponsor?.title}</h3>

                      {templateDetail.sponsor?.image_url ? (
                        <div className="sponsor-block__image">
                          <img src={templateDetail.sponsor.image_url} />
                        </div>
                      ) : null}

                      <p className="sponsor-block__text">{templateDetail?.sponsor?.website}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="section-block section-block--grey-xl">
              <div className="section-block-content">
                <h4 className="topic-info__subtitle">Other topics you might like</h4>
                <div>
                  <Carousel
                    className="related-slider"
                    infiniteLoop={true}
                    autoPlay={false}
                    centerMode={true}
                    centerSlidePercentage={33.33}
                    showStatus={false}
                    showArrows={true}
                    emulateTouch={true}
                    showThumbs={false}
                  >
                    {relatedTemplates}
                  </Carousel>
                </div>
              </div>
            </div>
          </>
        )}

        <Modal open={showDeleteConfirm} onClose={this.closeModal} center>
          <h2 className="topic-resources__title">Confirm Delete</h2>
          <div>Deleting your saved book cannot be undone - are you sure you want to continue?</div>
          <div className="templete-actions-right mt-5 float-right">
            <button className="btn btn--sml btn--secondary" onClick={this.closeModal}>
              Close
            </button>
            <button className="btn btn--sml btn--danger" onClick={this.onConfirmDelete}>
              Delete
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}


export default TopicDetail;

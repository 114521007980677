import React, { Component } from "react";
 
class MenuButton extends Component {
  render() {
    return (
      <button className="app-header-menu__item"
              onMouseDown={this.props.handleMouseDown}>
        {this.props.children}
      </button>
    );
  }
}
 
export default MenuButton;
import React, { Component } from 'react';
//import PrintessUi from './Printess-Ui/Printess';

class MemberArea extends Component {

    constructor(props) {
        super(props);

        this.onHandleLoginClick = this.onHandleLoginClick.bind(this);
        this.onHandleRegisterClick = this.onHandleRegisterClick.bind(this);

    this.props.onHideFavorite(true);

    }

    onHandleLoginClick() {
        this.props.history.push("/login");
    }

    onHandleRegisterClick() {
        this.props.history.push("/register");
    }

    render() {
        return (
            <div className='content-container full-page flex-col-center bgc-y-grade'>
               <div className="form-card-container th-fade-in th-shadow-down">
                    <div className="form-card-inner-container has-validation-callback">
                        <h2 className="t-ctr">Member Area</h2>
                        <p className='t-ctr t-grey'>You can access all our topic templates in the member area. Start creating amazing stories at school or home today!</p>
                        <div className="inline-button-container">
                            <button className="th-btn" onClick={ this.onHandleLoginClick } >Login</button>
                            <button className="th-btn" onClick={ this.onHandleRegisterClick } >Register</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MemberArea;
import React, { Component } from 'react';

import { connect } from 'react-redux';
import { requestFetchOrderHistory } from '../../store/actions';

import OrderHistoryEntry from './OrderHistoryEntry';

class OrderHistoryList extends Component {
    
    constructor(props) {
        super(props);

        this.onItemViewClick = this.onItemViewClick.bind(this);
    }

    componentDidMount() {
        this.props.requestFetchOrderHistory(this.props.getRequestHeaders());
    }
    

    onItemViewClick(_item){
        this.props.requestOrderHistoryDetails(_item.id);
        this.props.onNavigateToClientPage(`/order-history/${_item.id}/details`);
    }

    render() {

        if(this.props.orderHistory === undefined || this.props.orderHistory === null)  {
            return null;
        }

        const renderedItems = this.props.orderHistory.map((order, i) => {

            return (

                <OrderHistoryEntry 
                    key={i} 
                    order = { order } 
                    onItemViewClick = { this.onItemViewClick }  />

            );
        });

        if(this.props.orderHistory.length < 1) {
            return (<p>No orders.</p>);
        }
        
        return (
            
                <div className='app-table-content'>

                    <table className="app-table">

                        <thead>
                            <tr>
                                <th>Order Ref</th>
                                <th>Complete</th>
                                <th>Date</th>
                                <th className='app-table__button'></th>
                            </tr>

                        </thead>
                        <tbody>
                            { renderedItems }
                        </tbody>
                    </table>

                </div>
            
            
        );
    }
}

const mapStateToProps = (state) => {
    
    return {

        orderHistory: state.orderHistory
        
    };
};

export default connect(
    mapStateToProps, 
    { requestFetchOrderHistory }
)(OrderHistoryList);
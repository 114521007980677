import React, { Component } from 'react';
import Loader from '../../../components/Loader';
import { Input } from 'reactstrap';

class EditSponsor extends Component {

    constructor(props){
        super(props);

        this.state = {
            changes: null
        }

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeWebsite = this.onChangeWebsite.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSaveChanges = this.onSaveChanges.bind(this);

        this.onChangeLogoImage = this.onChangeLogoImage.bind(this);

        this.onSubmitLogoImageForm = this.onSubmitLogoImageForm.bind(this);
         this.props.onHideFavorite(true);

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.changes !== props.sponsor) {
          return {
            changes: props.sponsor,
          }
        }
        return null
    }

    onChangeTitle(_e){

        var { changes } = this.state;
        changes.title = _e.target.value;
        this.onSaveChanges(changes);
    }

    onChangeWebsite(_e) {
        var { changes } = this.state;
        changes.website = _e.target.value;
        this.onSaveChanges(changes);
    }


    onSaveChanges(_sponsor) {
        this.setState({
            changes: _sponsor
        });
    }


    onSubmitForm(_e) {
        console.log("on edit form submitted", _e);
        _e.preventDefault();

        const { changes } = this.state;

        const sponsor = {
            id: changes.id,
            title: changes.title, 
            website: changes.website
        };

        this.props.requestUpdateSponsor(sponsor);

        
    }

    onSubmitLogoImageForm(_e) {
        _e.preventDefault();
        if(this.state.logoImage === null) {
            return;
        }
        let form_data = new FormData();
        form_data.append('image', this.state.logoImage, this.state.logoImage.name);
        const changes = this.state.changes;
        this.props.requestUpdateSponsorImage(changes.id, form_data);
    }

    onChangeLogoImage = (e) => {
        this.setState({
          logoImage: e.target.files[0]
        });
    };

    render() {

        const { changes } = this.state;
        if(changes === null) {
            return <Loader />
        }

        const isEditMode = changes.id.length > 0;
        const titleText = isEditMode === true ? "Edit Sponsor" : "Add Sponsor";

        return (
           

            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font t-purple spc-b-s">{ titleText }</h2>

                        <p className="spc-b-l">Update the name of the sponsor.</p>

                        <form className="th-form-container has-validation-callback" onSubmit={ this.onSubmitForm }>

                            <div className="th-form-inner">
                                
                                <div className="th-field-wrapper">

                                    <label>Sponsor Title</label>

                                    <input type="text" placeholder="Title" onChange={ this.onChangeTitle } value={ changes.title } />

                                </div>
                                <div className="th-field-wrapper">
                                    <label>Sponsor Website</label>
                                    <input type="text" placeholder="Website" onChange={ this.onChangeWebsite } value={ changes.website } />
                                </div>

                                <div className="form-buttons">
                                    <button className="th-admin-btn" type="submit">Save Changes</button>
                                </div>
                            </div>
                        </form>

                        

                    </div>

                    {
                        (isEditMode === true) ? (
                            <div className='th-panel th-br bgc-white pall-l'>
                                <h2 className="hw-font spc-b-s t-purple">Sponsor Image Upload</h2>
                                <p className="">Upload sponsor logo</p>

                                {
                                    (changes.image_url) ? (
                                        <img className='ssor-logo' src={ changes.image_url } />
                                    ) : null
                                }

                                <form onSubmit={ this.onSubmitLogoImageForm }>
                                    <div className='spc-b-l'>
                                        <label htmlFor="tags">Select Files</label>
                                        <Input type="file" id="logo_img" accept="image/png, image/jpeg" onChange={ this.onChangeLogoImage } required />
                                    </div>

                                    <div className="d-flex flex-row flex-ai-l">
                                        <button className='th-admin-btn'>Upload Image</button>
                                    </div>
                                </form>
                            </div>
                        ) : null
                    }
                    

                </div>

            </div>
        );
    }
}

export default EditSponsor;

import React, { Component } from 'react';
import BasketList from '../../components/basket-list/BasketList';


class Basket extends Component {

    constructor(props) {
        super(props);
        this.props.onShowHeader();
        this.props.onHideFavorite(true);
        
    }

    render() {

        return (
            <div className='section-block'>

                <div className='section-block-content'>

                    <h2 className="section-block-content__title">Your basket</h2>
                    <p className='section-block-content__text'>Here's your basket items. Checkout, and look forward to recieving your personalised printed book.</p>

                    <BasketList 
                        requestCheckout = { this.props.requestCheckout }
                        getRequestHeaders = { this.props.getRequestHeaders }
                        requestRemoveFromBasket = { this.props.requestRemoveFromBasket }
                    />
 
                </div>
            </div>
        );

    }

}

export default Basket;



